/* App.css */

/* Apply background to the entire page */
html, body, #root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: linear-gradient(135deg, #0f2027, #203a43, #2c5364); /* Base gradient */
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
  color: white; /* Ensure text remains white */
}

/* Add texture overlay */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
  opacity: 0.3;
  z-index: -1; /* Make sure this stays behind everything */
  pointer-events: none; /* Allow interaction with the content */
}

/* Container for App.js */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content area to grow and take up space */
.content-wrapper {
  flex-grow: 1;
}

/* Text color should stay white */
.text-white {
  color: white;
}

